import React from 'react';
import { Link } from 'gatsby';

import Styled, { ThemeProvider } from 'styled-components';

import SVG from '../../primespot-ui/components/svg';
import Gravatar from '../../primespot-ui/components/gravatar';

import Layout from '../../layouts/mainLayout';

import theme from '../../styles/theme';

const BlogPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Wrapper>
          <h1>Check out our blog</h1>
          <ul>
            <li>
              <Link to="/blog/carpet-buying-guide">
                <Meta
                  author="Sci-Tech Carpet Cleaning Team"
                  date="December 20th, 2019"
                />
                <h2>The Ultimate Carpet Buying Guide</h2>
                <p>
                  Buying carpets can be a hassle. A big part of the problem is
                  having no idea what you should be buying. In this guide I will
                  explain all of the most popular options and give my
                  recommendations regarding which is best.
                </p>
                <p className="cta">
                  Read <SVG image="arrow-thin-right" />
                </p>
              </Link>
            </li>
            <li>
              <Link to="/blog/keep-carpets-cleaner-longer">
                <Meta
                  author="Sci-Tech Carpet Cleaning Team"
                  date="April 30th, 2019"
                />
                <h2>How to Keep Carpets Cleaner; Longer. A 7-Step Guide.</h2>
                <p>
                  Keeping carpets clean can be a challenge. Despite this, it's a
                  crucial step to home maintenance due to the fact that dirty
                  carpets are unhealthy as well as unsightly. This 7-step guide
                  will give you some tips for overcoming dirty carpets and
                  maintaining a beautiful home.
                </p>
                <p className="cta">
                  Read <SVG image="arrow-thin-right" />
                </p>
              </Link>
            </li>
            <li>
              <Link to="/blog/do-it-yourself-tips">
                <Meta
                  author="Sci-Tech Carpet Cleaning Team"
                  date="April 4th, 2019"
                />
                <h2>Do-It-Yourself Tips</h2>
                <p>
                  When the new dog has just made a mess on the carpet, you don’t
                  always have time to grab the phone and schedule a carpet
                  cleaning appointment. Sometimes you need to take matters into
                  your own hands before a small problem becomes a stain that
                  won’t come out.
                </p>
                <p className="cta">
                  Read <SVG image="arrow-thin-right" />
                </p>
              </Link>
            </li>
            <li>
              <Link to="/blog/professional-carpet-cleaning-information">
                <Meta
                  author="Sci-Tech Carpet Cleaning Team"
                  date="April 4th, 2019"
                />
                <h2>Professional Carpet Cleaning Information</h2>
                <p>
                  When considering professional carpet cleaning services, you
                  may have a lot of questions. This is especially true if you
                  haven’t had your carpets cleaned in some time.
                </p>
                <p className="cta">
                  Read <SVG image="arrow-thin-right" />
                </p>
              </Link>
            </li>
          </ul>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  );
};

const Meta = ({ author = 'Sci-Tech Carpet Cleaning Team', date }) => {
  if (!date) {
    return <div />;
  }
  return (
    <div className="meta">
      <Gravatar
        email="scitechcarpets@gmail.com"
        size={48}
        className="gravatar"
      />
      <div className="bio">
        <div className="author">{author}</div>
        <div className="date">{date}</div>
      </div>
    </div>
  );
};

const Wrapper = Styled.div`
  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    padding: 12px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 24px;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    p.cta {
      display: inline-flex;
      align-items: center;
    }
  }

  p.cta {
    color: ${({ theme }) => theme.primary.colors.lightBlue.darker};
    text-decoration: none;
    transition: all .3s ease;

    &:hover {
      color: ${({ theme }) => theme.primary.colors.lightBlue.lightest};
    }
  }

  .svg__arrow-thin-right {
    fill: ${({ theme }) => theme.primary.colors.lightBlue.darker};
    width: 15px;
    height: 15px;
    margin-left: 9px;
    transition: all .3s ease;
  }

  a:hover .svg__arrow-thin-right {
    fill: ${({ theme }) => theme.primary.colors.lightBlue.lightest};
    margin-left: 12px;
  }

  .meta {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .gravatar {
    border-radius: 50%;
  }

  .bio {
    margin-left: 24px;
  }

  .author {
    font-size: 16px;
    color: ${({ theme }) => theme.primary.colors.black};
    margin-bottom: 6px;
  }

  .date {
    font-size: 14px;
    color: ${({ theme }) => theme.primary.colors.gray.darker};
  }
`;

export default BlogPage;
